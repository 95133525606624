import { inView, animate } from "motion";
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import "./main.css";

Alpine.plugin(intersect);

window.Alpine = Alpine;
Alpine.start();

const domReady = () => {
  let transitionableBlocks = document.querySelectorAll(".transition-block");
  const totalRaised = document.querySelectorAll(".giantpeach-totalraised");

  let inViewStop = inView(
    transitionableBlocks,
    ({ target }) => {
      target.classList.add("is-visible");

      return () => {
        target.classList.remove("is-visible");
      };
    },
    { margin: "0px 0px -15% 0px" },
  );

  document.addEventListener("block:load", () => {
    transitionableBlocks = document.querySelectorAll(".transition-block");
    inViewStop();
    inViewStop = inView(transitionableBlocks, ({ target }) => {
      target.classList.add("is-visible");

      return () => {
        target.classList.remove("is-visible");
      };
    });
  });

  if (totalRaised.length) {
    import("./Blocks/TotalRaised/countup").then(({ default: Countup }) => {
      Countup();
    });
  }
};

document.addEventListener("DOMContentLoaded", domReady);
